<bit-section [formGroup]="sendDetailsForm">
  <bit-section-header>
    <h2 bitTypography="h5">{{ "sendDetails" | i18n }}</h2>
  </bit-section-header>

  <bit-card>
    <bit-form-field>
      <bit-label>{{ "name" | i18n }}</bit-label>
      <input bitInput type="text" formControlName="name" />
    </bit-form-field>

    <tools-send-text-details
      *ngIf="config.sendType === TextSendType"
      [config]="config"
      [originalSendView]="originalSendView"
      [sendDetailsForm]="sendDetailsForm"
    ></tools-send-text-details>

    <bit-form-field>
      <bit-label>{{ "deletionDate" | i18n }}</bit-label>
      <bit-select
        id="deletionDate"
        name="SelectedDeletionDatePreset"
        formControlName="selectedDeletionDatePreset"
      >
        <bit-option
          *ngFor="let o of datePresetOptions"
          [value]="o.value"
          [label]="o.name"
        ></bit-option>
      </bit-select>
      <bit-hint>{{ "deletionDateDescV2" | i18n }}</bit-hint>
    </bit-form-field>
  </bit-card>
</bit-section>
